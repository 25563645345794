<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Steuersätze</h4>
                            <p>Liste aller Steuersätze</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-default mr-1" @click="loadTaxes">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('taxes.create')">
                                <i class="fas fa-plus-circle"></i> Neuer Steuersatz
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Suchen..."></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="taxes" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('taxes.edit')"><i class="fas fa-edit"></i></b-button>
                                        <b-button size="sm" @click="deleteTax(row.item.id)" variant="danger" v-if="$auth.check('taxes.destroy')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="taxModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Steuersatz</label>
                            <div class="col-sm-9">
                                <input v-model="form.tax" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('tax')}" tabindex="1"/>
                                <has-error :form="form" field="tax"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">

                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                            <div class="col-sm-9">
                                <input v-model="form.description" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('description')}" tabindex="2"/>
                                <has-error :form="form" field="description"></has-error>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'settings.taxes',
    title: 'Steuersätze',

    data() {
        return{
            form: new window.Form({
                id: "",
                tax: "",
                description: "",
            }),
            taxes: [],
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", label: "ID", sortable: true},
                {key: "tax", label: "Steuersatz", sortable: true},
                {key: "description", label: "Beschreibung", sortable: true},
                {key: "actions", label: "Actions"},
            ],
            editMode: false,
            modalTitle: '',
        }
    },

    methods: {
        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editTax(id);
            }
            else
            {
                //Create User
                this.createTax();
            }
        },

        createModal() {
            this.editMode = false;
            this.modalTitle = "Neueen Steuersatz anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("taxModal");
        },

        editModal(Tax) {
            this.editMode = true;
            this.modalTitle = "Steuersatz editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Tax);
            this.$bvModal.show("taxModal");

        },

        createTax() {
            this.$Progress.start();
            this.form
                .post("/taxes")
                .then(() => {
                    this.$bvModal.hide("taxModal");
                    this.$swal({
                        icon: "success",
                        title: "Steuersatz wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadTaxes();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        editTax(id){
            this.$Progress.start();
            this.form
                .put("/taxes/"+ id)
                .then(() => {
                    this.$bvModal.hide("taxModal");
                    this.$swal({
                        icon: "success",
                        title: "Steuersatz wurde editiert",
                    });
                    this.$Progress.finish();
                    this.loadTaxes();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteTax(id){
            this.$swal({
                title: "Möchtest du den Steuersatz wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/taxes/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Steuersatz erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadTaxes();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },


        async loadTaxes() {
            this.$Progress.start();
            await this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadTaxes();
    },

    computed: {
        tableData() {
            return this.taxes || [];
        },

        rows() {
            return this.taxes.length;
        },
    },

}
</script>

<style>

</style>